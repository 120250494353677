body {
  margin: 0;
  font-family:
    Evolventa,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* normal */
@font-face {
  font-family: Evolventa;
  font-style: normal;
  font-weight: normal;
  src: url('../public/assets/fonts/Evolventa-Regular.ttf');
  src: url('../public/assets/fonts/Evolventa-Regular.otf');
}
/* bold */
@font-face {
  font-family: Evolventa;
  font-style: normal;
  font-weight: bold;
  src: url('../public/assets/fonts/Evolventa-Bold.ttf');
  src: url('../public/assets/fonts/Evolventa-Bold.otf');
}
/* Oblique */
@font-face {
  font-family: Evolventa;
  font-style: italic;
  font-weight: normal;
  src: url('../public/assets/fonts/Evolventa-Oblique.ttf'),
    url('../public/assets/fonts/Evolventa-Oblique.otf');
}
/* ObliqueBold */
@font-face {
  font-family: Evolventa;
  font-style: italic;
  font-weight: bold;
  src: url('../public/assets/fonts/Evolventa-BoldOblique.ttf'),
    url('../public/assets/fonts/Evolventa-BoldOblique.otf');
}
