a {
  color: inherit;
  text-decoration: none;
}

.fade-enter {
  opacity: 0;
  transform: translate(100%);
}
.fade-enter-active {
  opacity: 1;
  /*   scale: (2); */
  transform: translate(0%);
}
.fade-exit {
  opacity: 1;

  transform: translate(0%, 0%);
}
.fade-exit-active {
  opacity: 0;

  transform: translate(-100%);
}
.fade-enter-active,
.fade-exit-active {
  transition:
    opacity 300ms linear,
    transform 300ms linear;
}
/* opacity */

.opacity-enter {
  opacity: 0;
}
.opacity-enter-active {
  opacity: 1;
  /*   scale: (2); */
}
.opacity-exit {
  opacity: 1;
}
.opacity-exit-active {
  opacity: 0;
}
.opacity-enter-active {
  transition: opacity 200ms linear;
}
.opacity-exit-active {
  transition: opacity 100ms linear;
}
